import Modal from "@/Components/Modal";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
    closePopupModal,
    toggleViewedPopupModal,
} from "@/redux/slices/appSettingSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { usePage } from "@inertiajs/react";
import { isNull } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function () {
    const {
        locale: { dir },
        appSetting: {
            popupModal: { nextView, viewed },
        },
    } = useAppSelector((state) => state);
    const { setting }: any = usePage().props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (setting.show_popup) {
            if (isNull(nextView)) {
                dispatch(toggleViewedPopupModal(false));
            } else if (
                moment(nextView).locale("en").isValid() &&
                moment()
                    .locale("en")
                    .isSameOrAfter(moment(nextView).locale("en"))
            ) {
                dispatch(toggleViewedPopupModal(false));
            } else {
                dispatch(toggleViewedPopupModal(true));
            }
        } else {
            dispatch(toggleViewedPopupModal(false));
            dispatch(closePopupModal());
        }
    }, []);

    const closeModal = () => {
        dispatch(toggleViewedPopupModal(true));
        dispatch(closePopupModal());
    };

    return (
        <Modal show={!viewed} onClose={() => closeModal()} maxWidth="lg">
            <div
                dir={dir}
                className="flex flex-col justify-start items-center rounded-2xl   lg:max-w-[600px]"
            >
                <button className="flex flex-row justify-center items-center">
                    <XMarkIcon
                        onClick={() => closeModal()}
                        className="absolute left-6  top-8 mx-3 w-6 h-6 text-gray-600"
                    />
                </button>
                <a
                    target="_blank"
                    href={setting.popup_url}
                    className="flex justify-start w-full h-full items-center p-4 px-6 hover:bg-gray-100  "
                >
                    <img
                        src={setting.popup_image}
                        className="w-full h-full object-cover "
                    />
                </a>
            </div>
        </Modal>
    );
}
