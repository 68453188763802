import {getImage, searchModules} from '@/constants';
import {useAppDispatch, useAppSelector} from '@/redux/hooks';
import {RootState} from '@/redux/store';
import {PageProps} from '@/types';
import {Link, usePage} from '@inertiajs/react';
import {filter, map} from 'lodash';
import {ChevronLeft, ChevronRight} from 'lucide-react';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';

export default function () {
    const {t} = useTranslation();
    const {currentLang}: PageProps['lang'] = usePage().props;
    const {
        locale: {isRTL, lang, dir},
        appSetting: {dateSelected},
    } = useAppSelector((state: RootState) => state);
    const refSlider = useRef<Slider | null>(null);
    const dispatch = useAppDispatch();

    var settings = {
        rtl: isRTL,
        dots: false,
        // className: "center",
        // centerMode: true,
        infinite: false,
        // centerPadding: "60px",
        // adaptiveHeight: true,
        swipeToSlide: false,
        speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // initialSlide: 1,
        // focusOnSelect: true,
        arrows: false,
    };

    const RenderArrows = () => {
        return (
            <div className="relative z-50 hidden lg:flex w-[110%] md:w-[108%] xl:w-[105%] h-auto  justify-end gap-x-4 items-center m-auto top-16 rtl:-right-28 ltr:-left-28">
                <ChevronLeft
                    onClick={() => refSlider?.current?.slickPrev()}
                    className="relative rtl:rotate-180  h-8 w-8 text-white border border-theme-400 bg-theme-600 hover:bg-theme-800 rounded-xl p-2"
                />
                <ChevronRight
                    onClick={() => refSlider?.current?.slickNext()}
                    className="relative rtl:rotate-180  h-8 w-8 text-white border border-theme-400 bg-theme-600 hover:bg-theme-800 rounded-xl p-2"
                />
            </div>
        );
    };
    return (
        <div className="p-4">
            <RenderArrows />
            <Slider {...settings} ref={refSlider}>
                {map(
                    filter(searchModules, (s) => s.showFront),
                    (s, i) => (
                        <div dir={dir} key={i}>
                            <div className="flex flex-col lg:flex-row  justify-between items-center p-8 rounded-xl bg-theme-100  ">
                                <div className=" flex flex-1 justify-start items-start flex-col gap-y-6 p-4 xl:p-12">
                                    <div className="text-3xl text-prim-800 capitalize">
                                        {t(s.name)}
                                    </div>
                                    <div className="text-xl text-prim-600 capitalize">
                                        {t(`${s.name}_message`)}
                                    </div>
                                    <Link
                                        href={route(
                                            `${currentLang}.frontend.course.index`,
                                            {...s.query},
                                        )}
                                        className="text-center rounded-3xl p-3 px-12 border border-theme-700 text-theme-700 hover:bg-theme-700 hover:text-white capitalize"
                                    >
                                        {t('display_courses')}
                                    </Link>
                                </div>
                                <div className="flex-1  ">
                                    <img
                                        src={getImage(`${s.name}.png`)}
                                        className="w-3/4 h-auto object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    ),
                )}
            </Slider>
        </div>
    );
}
