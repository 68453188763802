import CategoryCard from '@/Components/Course/CategoryCard';
import CourseSlider from '@/Components/Course/CourseSlider';
import CourseTypesSlider from '@/Components/Course/CourseTypesSlider';
import AboutusSection from '@/Components/Home/AboutusSection';
import EquivalentRateSection from '@/Components/Home/EquivalentRateSection';
import SearchSection from '@/Components/Home/SearchSection';
import MainSlider from '@/Components/MainSlider';
import PopupModal from '@/Components/PopupModal';
import UserCard from '@/Components/User/UserCard';
import MainContent from '@/Pages/Frontend/Partials/MainContent';
import {getIcon, getImage, whatsappUrl} from '@/constants';
import {localeType, PageProps, Setting, User} from '@/types';
import {Category, Course, Grade, Slide} from '@/types/queries';
import {Link} from '@inertiajs/react';
import {map} from 'lodash';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

export default function ({
    slides,
    categories,
    grades,
    teachers,
    courses,
    currentLang,
    setting,
}: PageProps<{
    currentLang: localeType;
    categories: Category[];
    grades: Grade[];
    slides: Slide[];
    setting: Setting;
    teachers: User[];
    courses: Course[];
}>) {
    const {t} = useTranslation();
    return (
        <MainContent>
            {setting.show_popup && <PopupModal />}
            {slides && <MainSlider slides={slides} />}
            {/* search */}
            <SearchSection />
            {/* categories */}
            {categories && categories.length > 0 && (
                <div className="py-12 sm:py-8 bg-white rounded-3xl flex flex-1 w-full flex-col gap-y-6 justify-center items-center">
                    <div className="mx-auto w-full px-2 text-center lg:px-6">
                        <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                                {t('categories')}
                            </h2>
                            <ReactSVG
                                src={getIcon('title_arrow.svg')}
                                className="relative w-20 text-orange-700 ltr:rotate-180"
                            />
                            <p className="text-lg leading-8 text-gray-600">
                                {t('categories_message')}
                            </p>
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-10 flex flex-row flex-1 flex-wrap justify-start items-center border border-gray-100 rounded-3xl divide-x divide-y divide-gray-100"
                        >
                            {map(categories, (element: any, i: number) => (
                                <CategoryCard
                                    element={element}
                                    key={i}
                                    queryObject={{category_id: element.id}}
                                    showBorder={true}
                                />
                            ))}
                        </ul>
                    </div>
                    <Link
                        className="text-center rounded-3xl p-3 px-6 border border-theme-700 text-theme-700 hover:bg-theme-700 hover:text-white capitalize"
                        href={route(`${currentLang}.frontend.course.index`)}
                    >
                        {t('see_more')}
                    </Link>
                </div>
            )}
            <AboutusSection />
            {/* courses */}
            {courses && courses.length > 0 && (
                <div className="bg-white py-12 sm:py-8 rounded-3xl">
                    <div className="mx-auto  px-6 lg:px-8">
                        <div className="mx-auto  lg:max-w-none">
                            <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                                <Link
                                    href={route(
                                        `${currentLang}.frontend.course.index`,
                                    )}
                                >
                                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                                        {t('courses')}
                                    </h2>
                                </Link>

                                <ReactSVG
                                    src={getIcon('title_arrow.svg')}
                                    className="relative w-20 text-orange-700 ltr:rotate-180"
                                />
                                <p className="text-lg leading-8 text-gray-600">
                                    {t('courses_message')}
                                </p>
                            </div>
                            <CourseSlider elements={courses} />
                        </div>
                    </div>
                </div>
            )}
            {/* inqueries & login */}
            <div className="overflow-hidden bg-gradient-to-r from-black to-teal-700 py-14 sm:py-24 rounded-3xl">
                <div className="mx-auto px-6 lg:px-8">
                    <div className="mx-auto grid  grid-cols-1 gap-x-8 gap-y-10  lg:grid-cols-2 justify-center items-center">
                        <div className="lg:max-w-lg flex flex-col gap-y-6 ps-8">
                            <p className="text-3xl font-bold tracking-tight text-white sm:text-4xl break-words leading-loose">
                                {t('do_u_have_any_inquires_about_the_platform')}
                            </p>
                            <div className="flex flex-col lg:flex-row justify-between items-center gap-4">
                                <Link
                                    className="w-full text-center rounded-3xl p-3 px-6 border border-white text-white hover:bg-theme-200 hover:text-theme-700 capitalize"
                                    href={route(
                                        `${currentLang}.frontend.contactus`,
                                    )}
                                >
                                    {t('contactus_now')}
                                </Link>
                                <Link
                                    className="w-full  text-center rounded-3xl p-3 px-6 border border-white text-white hover:bg-theme-200 hover:text-theme-700 capitalize"
                                    href={route(`${currentLang}.register`)}
                                >
                                    {t('or_register_now')}
                                </Link>
                            </div>
                            <div>
                                <a
                                    target="_blank"
                                    href={whatsappUrl(setting.whatsapp)}
                                    className="text-white text-lg hover:underline hover:shadow-lg hover:decoration-theme-700"
                                >
                                    {t(
                                        'or_speak_directly_to_our_customer_services',
                                    )}
                                </a>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <img
                                loading="lazy"
                                src={getIcon('newsletter.svg')}
                                alt={setting.name}
                                className="w-1/2 h-auto object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* grades */}
            {grades && grades.length > 0 && (
                <div className=" py-12 sm:py-8 bg-white rounded-3xl flex flex-1 w-full flex-col gap-y-6 justify-center items-center">
                    <div className="mx-auto w-full px-2 text-center lg:px-6">
                        <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                                {t('grades')}
                            </h2>
                            <ReactSVG
                                src={getIcon('title_arrow.svg')}
                                className="relative w-20 text-orange-700 ltr:rotate-180"
                            />
                            <p className="text-lg leading-8 text-gray-600">
                                {t('grades_message')}
                            </p>
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-10 flex flex-row flex-1 flex-wrap justify-start items-center border border-gray-100 rounded-3xl divide-x divide-y divide-gray-100"
                        >
                            {map(grades, (element: any, i: number) => (
                                <CategoryCard
                                    element={element}
                                    queryObject={{grade_id: element.id}}
                                    key={i}
                                    showBorder={true}
                                />
                            ))}
                        </ul>
                    </div>
                    <Link
                        className="text-center rounded-3xl p-3 px-6 border border-theme-700 text-theme-700 hover:bg-theme-700 hover:text-white capitalize"
                        href={route(`${currentLang}.frontend.course.index`)}
                    >
                        {t('see_more')}
                    </Link>
                </div>
            )}

            <EquivalentRateSection />

            {/* course_types */}
            <div className="bg-white py-12 sm:py-8 rounded-3xl  overflow-hidden">
                <div className="mx-auto  px-6 lg:px-8">
                    <div className="mx-auto  lg:max-w-none">
                        <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                                {t('courses_types')}
                            </h2>
                            <ReactSVG
                                src={getIcon('title_arrow.svg')}
                                className="relative w-20 text-orange-700 ltr:rotate-180"
                            />
                            <p className="text-lg leading-8 text-gray-600">
                                {t('courses_types_message')}
                            </p>
                        </div>
                        <CourseTypesSlider />
                    </div>
                </div>
            </div>
            {/* partners */}
            {teachers && teachers.length > 0 ? (
                <div className="bg-white grid grid-cols-1 md:grid-cols-2 justify-center items-center rounded-3xl ">
                    <div className="relative isolate col-span-1 w-full h-60 md:h-full flex justify-center items-center">
                        <img
                            loading="lazy"
                            className="absolute inset-0 w-full h-60 md:h-full object-cover z-0 rounded-t-3xl sm:rounded-t-none sm:rtl:rounded-r-3xl sm:ltr:rounded-l-3xl"
                            src={getImage('about-us.jpg')}
                            alt={setting.name}
                        />
                        <div className="relative w-full h-full bg-white/20 backdrop-blur-sm rounded-xl p-10 z-50  flex flex-1 justify-center items-start">
                            <div className="w-full flex flex-1 flex-col gap-y-4 justify-start px-8 items-center text-gray-800">
                                <h1 className="header-one xl:text-[28px] text-center text-white drop-shadow-lg">
                                    {t('success_parteners')}
                                </h1>
                                <p className="xl:text-lg text-white drop-shadow-lg leading-8">
                                    {t('success_message')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-16 sm:py-22 px-6 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                        {map(teachers, (u, i: number) => (
                            <UserCard key={i} element={u} />
                        ))}
                    </div>
                </div>
            ) : null}

            {/* inqueries & login */}
            <div className="bg-white overflow-hidden p-4 sm:p-8  rounded-3xl">
                <div
                    className="mx-auto p-4 sm:p-8 bg-local rounded-3xl"
                    style={{
                        backgroundImage: `url(${getImage('bg.jpg')})`,
                        backgroundRepeat: 'repeat',
                        backgroundSize: '600px',
                    }}
                >
                    <div className="mx-auto grid  grid-cols-1 gap-x-8 gap-y-10  lg:grid-cols-2 justify-center items-center">
                        <div className="lg:max-w-lg flex flex-col gap-y-6 ps-8">
                            <p className="text-3xl font-bold tracking-tight text-prim-900 sm:text-4xl break-words leading-loose">
                                {t('join_us_the_bigest_plateform_in_kuwait')}
                            </p>
                            <div className="flex flex-col lg:flex-row justify-between items-center gap-4">
                                <a
                                    target="_blank"
                                    href={whatsappUrl(
                                        setting.hr_mobile ?? setting.whatsapp,
                                    )}
                                    className="w-auto text-center rounded-3xl p-3 px-6 text-white bg-theme-800 hover:bg-theme-600 capitalize"
                                >
                                    {t('join_us_now')}
                                </a>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <img
                                loading="lazy"
                                src={getImage('join_us.png')}
                                alt={setting.name}
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MainContent>
    );
}
