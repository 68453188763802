import TextInput from '@/Components/TextInput';
import {getIcon, searchModules} from '@/constants';
import {useAppDispatch, useAppSelector} from '@/redux/hooks';
import {setSearchModule} from '@/redux/slices/appSettingSlice';
import {Label} from '@/shadcn/ui/label';
import {router, useForm, usePage} from '@inertiajs/react';
import {filter, first, map, toString} from 'lodash';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import {motion} from 'framer-motion';
interface FormProps {
    search: string;
}
export default function () {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {
        currentLang,
        ziggy: {query},
    }: any = usePage().props;
    const {
        appSetting: {searchModule},
    } = useAppSelector((state) => state);
    const {data, setData, get, reset, processing, progress} =
        useForm<FormProps>({
            search: query.search ?? ``,
        });

    const handleSelectSearchModule = (id: number | string) => {
        const currentSearchModule = first(
            filter(searchModules, (s) => s.id == id),
        );
        if (currentSearchModule) {
            dispatch(setSearchModule(currentSearchModule));
        }
    };

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (searchModule && searchModule.showFront) {
            router.get(
                toString(
                    route(
                        `${currentLang}.frontend.${searchModule.type}.index`,
                        {
                            ...(searchModule.query && {
                                ...searchModule.query,
                            }),
                            ...(data.search && {search: data.search}),
                        },
                    ),
                ),
            );
        } else {
            const searchModule = first(
                filter(searchModules, (s) => s.showFront),
            );
            router.get(
                toString(
                    route(
                        `${currentLang}.frontend.${searchModule.type}.index`,
                        {
                            ...(searchModule.query && {
                                ...searchModule.query,
                            }),
                            ...(data.search && {search: data.search}),
                        },
                    ),
                ),
            );
        }
    };

    return (
        <motion.div
            className="py-12 sm:py-8 bg-white rounded-3xl flex flex-1 w-full flex-col justify-center items-center"
            // initial={{ x: "100%" }}
            // animate={{ x: 0 }}
            // exit={{ x: "100%", transition: { duration: 5 } }}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 1, transition: {duration: 5}}}
        >
            <div className="mx-auto w-full px-2 text-center lg:px-6">
                <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                        {t('search')}
                    </h2>
                    <ReactSVG
                        src={getIcon('title_arrow.svg')}
                        className="relative w-20 text-orange-700 ltr:rotate-180"
                    />
                    <p className="text-lg leading-8 text-gray-600 pb-4">
                        {t('search_message')}
                    </p>
                </div>
                <div className="flex flex-1 flex-row justify-center items-center px-4 xl:px-0">
                    <form
                        className="w-full flex flex-row justify-center items-center "
                        onSubmit={handleSearch}
                    >
                        <div className="relative w-3/5  xl:w-2/5 ">
                            <div className="absolute inset-y-0 rlt:right-0 ltr:left-0 flex items-center">
                                <Label htmlFor="search" className="sr-only">
                                    {t('course')}
                                </Label>
                                <select
                                    autoComplete="search"
                                    onChange={(e) =>
                                        handleSelectSearchModule(e.target.value)
                                    }
                                    className="h-full w-30 rtl:rounded-r-xl ltr:rounded-l-xl border-0  py-0 pl-3 rtl:pr-7 ltr:pl-7 text-gray-500  focus:border-none focus:ring-0 sm:text-sm bg-gray-50 capitalize"
                                >
                                    {map(
                                        filter(
                                            searchModules,
                                            (m) => m.showFront,
                                        ),
                                        (s, i) => (
                                            <option value={s.id} key={i}>
                                                {t(s.name)}
                                            </option>
                                        ),
                                    )}
                                </select>
                            </div>
                            <TextInput
                                type="text"
                                name="search"
                                id="search"
                                onChange={(e) =>
                                    setData('search', e.target.value)
                                }
                                required
                                className="block w-full border-0 py-1.5 rtl:ps-40 ltr:ps-52  text-gray-900  placeholder:text-gray-400  sm:text-sm sm:leading-6 !bg-gray-50 rounded-none rtl:rounded-r-xl ltr:rounded-l-xl"
                                placeholder={t('search')}
                            />
                        </div>
                        <button className="text-center rtl:rounded-l-xl ltr:rounded-r-xl p-3 px-6 w-auto bg-theme-800 text-white hover:bg-theme-900 capitalize">
                            {t('search_now')}
                        </button>
                    </form>
                </div>
            </div>
        </motion.div>
    );
}
