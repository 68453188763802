import CourseCard from '@/Components/Course/CourseCard';
import {useAppDispatch, useAppSelector} from '@/redux/hooks';
import {RootState} from '@/redux/store';
import {map} from 'lodash';
import {ChevronLeft, ChevronRight} from 'lucide-react';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';

export default function ({elements}: any) {
    const {t} = useTranslation();
    const {
        locale: {isRTL, lang, dir},
        appSetting: {dateSelected},
    } = useAppSelector((state: RootState) => state);
    const refSlider = useRef<Slider | null>(null);
    const dispatch = useAppDispatch();

    var settings = {
        rtl: isRTL,
        dots: false,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '60px',
        adaptiveHeight: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
        // focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    initialSlide: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };

    const RenderArrows = () => {
        return (
            <div className="relative z-50 hidden lg:flex w-[110%] md:w-[108%] xl:w-[105%] h-0  justify-between items-center m-auto -bottom-60 rtl:-right-10 ltr:-left-10">
                <ChevronLeft
                    onClick={() => refSlider?.current?.slickPrev()}
                    className="relative rtl:rotate-180 ltr:right-2 rtl:left-2 h-8 w-8 text-gray-700 border border-gray-200 bg-gray-100 hover:bg-gray-200 rounded-xl p-2"
                />
                <ChevronRight
                    onClick={() => refSlider?.current?.slickNext()}
                    className="relative rtl:rotate-180 ltr:left-2 rtl:right-2 h-8 w-8 text-gray-700 border border-gray-200 bg-gray-100 hover:bg-gray-200 rounded-xl p-2"
                />
            </div>
        );
    };

    return (
        <div className="p-4 ">
            <RenderArrows />
            <Slider {...settings} ref={refSlider}>
                {map(elements, (s, i) => (
                    <div className="px-1 " key={i}>
                        <CourseCard element={s} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}
