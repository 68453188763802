import TextInput from '@/Components/TextInput';
import {getIcon, toEn} from '@/constants';
import {useAppDispatch} from '@/redux/hooks';
import {showWarningToastMessage} from '@/redux/slices/toastMessageSlice';
import {useForm} from '@inertiajs/react';
import {floor, map, omit, round, subtract, sum} from 'lodash';
import {FormEventHandler, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

type FormProps = {
    standardMarks: {
        high_school_percentage: number;
        subject_1: number;
        subject_2: number;
        subject_3: number;
        subject_4: number;
    };
    studentMarks: {
        high_school_percentage: number;
        subject_1: number;
        subject_2: number;
        subject_3: number;
        subject_4: number;
    };
    resultMarks: {
        high_school_percentage: number;
        subject_1: number;
        subject_2: number;
        subject_3: number;
        subject_4: number;
    };
    rate: number;
};

export default function () {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {data, setData, reset}: any = useForm<FormProps>({
        standardMarks: {
            high_school_percentage: 100,
            subject_1: 0,
            subject_2: 0,
            subject_3: 0,
            subject_4: 0,
        },
        studentMarks: {
            high_school_percentage: 0,
            subject_1: 100,
            subject_2: 100,
            subject_3: 100,
            subject_4: 100,
        },
        resultMarks: {
            high_school_percentage: 0,
            subject_1: 0,
            subject_2: 0,
            subject_3: 0,
            subject_4: 0,
        },
        rate: 0,
    });

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        setData((values: any) => ({
            ...values,
            [e.target.id]: toEn(e.target.value),
        }));
    };

    useEffect(() => {
        setData('standardMarks', {
            ...data.standardMarks,
            high_school_percentage: subtract(
                100,
                sum(
                    map(
                        omit(data.standardMarks, ['high_school_percentage']),
                        (s) => floor(s, 3),
                    ),
                ),
            ),
        });
    }, [
        data.standardMarks.subject_1,
        data.standardMarks.subject_2,
        data.standardMarks.subject_3,
        data.standardMarks.subject_4,
    ]);

    useEffect(() => {
        setData('studentMarks', {
            ...data.studentMarks,
            ...(data.standardMarks.subject_1 == 0 && {subject_1: 100}),
            ...(data.standardMarks.subject_2 == 0 && {subject_2: 100}),
            ...(data.standardMarks.subject_3 == 0 && {subject_3: 100}),
            ...(data.standardMarks.subject_4 == 0 && {subject_4: 100}),
        });
    }, [data.standardMarks.high_school_percentage]);

    useEffect(() => {
        const standardPercentage = data.standardMarks.high_school_percentage;
        const studentPercentage = floor(
            data.studentMarks.high_school_percentage,
            3,
        );
        if (studentPercentage >= 50 && studentPercentage <= 120) {
            setData('resultMarks', {
                ...data.resultMarks,
                high_school_percentage: floor(
                    (standardPercentage / 100) *
                        (studentPercentage / 100) *
                        100,
                    3,
                ),
                subject_1: floor(
                    (data.standardMarks.subject_1 / 100) *
                        (data.studentMarks.subject_1 / 100) *
                        100,
                    3,
                ),
                subject_2: floor(
                    (data.standardMarks.subject_2 / 100) *
                        (data.studentMarks.subject_2 / 100) *
                        100,
                    3,
                ),
                subject_3: floor(
                    (data.standardMarks.subject_3 / 100) *
                        (data.studentMarks.subject_3 / 100) *
                        100,
                    3,
                ),
                subject_4: floor(
                    (data.standardMarks.subject_4 / 100) *
                        (data.studentMarks.subject_4 / 100) *
                        100,
                    3,
                ),
            });
        } else {
            reset('resultMarks', 'rate');
            if (studentPercentage === 0 && standardPercentage !== 100) {
                dispatch(
                    showWarningToastMessage({
                        content: t(
                            'student_rate_must_be_at_least_50_to_start_calculating',
                        ),
                    }),
                );
            }
        }
    }, [data.studentMarks]);

    useEffect(() => {
        if (floor(data.resultMarks.high_school_percentage, 3) > 0) {
            setData(
                'rate',
                floor(sum(map(data.resultMarks, (s) => floor(s, 3))), 3),
            );
        }
    }, [data.resultMarks]);

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
    };
    return (
        <div className=" py-12 sm:py-8 bg-white rounded-3xl flex flex-1 w-full flex-col gap-y-6 justify-center items-center overflow-hidden">
            <div className="mx-auto w-full px-2 text-center lg:px-6">
                <div className="flex flex-1 flex-col justify-center items-center gap-y-4">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">
                        {t('equivalentـrate')}
                    </h2>
                    <ReactSVG
                        src={getIcon('title_arrow.svg')}
                        className="relative w-20 text-orange-700 ltr:rotate-180"
                    />
                    <p className="text-lg leading-8 text-gray-600">
                        {t('equivalentـrate_message')}
                    </p>
                </div>
                <form
                    onSubmit={submit}
                    className="mx-auto mt-10 flex flex-col lg:flex-row   w-full flex-wrap justify-start items-center border border-gray-100 rounded-3xl divide-y divide-gray-100"
                >
                    <div className="w-full grid grid-cols-1 lg:grid-cols-5 gap-4  bg-theme-100 rounded-t-3xl p-5">
                        <div className="text-gray-600">
                            {t('high_school_percentage')}
                        </div>
                        <div className="text-gray-600">{t('subject_1')}</div>
                        <div className="text-gray-600">{t('subject_2')}</div>
                        <div className="text-gray-600">{t('subject_3')}</div>
                        <div className="text-gray-600">{t('subject_4')}</div>
                    </div>
                    <div className="w-full grid grid-cols-1 lg:grid-cols-5 gap-4  justify-center items-center p-5  ">
                        <div>
                            {`${data.standardMarks.high_school_percentage} %`}
                        </div>
                        <div>
                            <select
                                name="standardMarks[subject_1]"
                                id="standardMarks[subject_1]"
                                onChange={(e) =>
                                    setData('standardMarks', {
                                        ...data.standardMarks,
                                        subject_1: toEn(e.target.value),
                                    })
                                }
                                className="bg-transparent rounded-xl ring-none border border-gray-100 focus:border-gray-100 focus:ring-theme-100 focus:ring-none capitalize"
                            >
                                <option value={0}>
                                    {t('choose_percentage')}
                                </option>
                                <option value={5}>5%</option>
                                <option value={7.5}>7.5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={20}>20%</option>
                            </select>
                        </div>
                        <div>
                            <select
                                name="standardMarks[subject_2]"
                                id="standardMarks[subject_2]"
                                onChange={(e) =>
                                    setData('standardMarks', {
                                        ...data.standardMarks,
                                        subject_2: toEn(e.target.value),
                                    })
                                }
                                className="bg-transparent rounded-xl ring-none border border-gray-100 focus:border-gray-100 focus:ring-theme-100 focus:ring-none capitalize"
                            >
                                <option value={0}>
                                    {t('choose_percentage')}
                                </option>
                                <option value={5}>5%</option>
                                <option value={7.5}>7.5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={20}>20%</option>
                            </select>
                        </div>
                        <div>
                            <select
                                name="standardMarks[subject_3]"
                                id="standardMarks[subject_3]"
                                onChange={(e) =>
                                    setData('standardMarks', {
                                        ...data.standardMarks,
                                        subject_3: toEn(e.target.value),
                                    })
                                }
                                className="bg-transparent rounded-xl ring-none border border-gray-100 focus:border-gray-100 focus:ring-theme-100 focus:ring-none capitalize"
                            >
                                <option value={0}>
                                    {t('choose_percentage')}
                                </option>
                                <option value={5}>5%</option>
                                <option value={7.5}>7.5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={20}>20%</option>
                            </select>
                        </div>
                        <div>
                            <select
                                name="standardMarks[subject_4]"
                                id="standardMarks[subject_4]"
                                onChange={(e) =>
                                    setData('standardMarks', {
                                        ...data.standardMarks,
                                        subject_4: toEn(e.target.value),
                                    })
                                }
                                className="bg-transparent rounded-xl ring-none border border-gray-100 focus:border-gray-100 focus:ring-theme-100 focus:ring-none capitalize"
                            >
                                <option value={0}>
                                    {t('choose_percentage')}
                                </option>
                                <option value={5}>5%</option>
                                <option value={7.5}>7.5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={20}>20%</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-1 lg:grid-cols-5 gap-4 justify-center items-center p-5  ">
                        <div>
                            <TextInput
                                className="w-3/4 text-center placeholder:text-xxs"
                                placeholder={t('student_percentage')}
                                name="studentMarks[high_school_percentage]"
                                id="studentMarks[high_school_percentage]"
                                onChange={(e) =>
                                    setData('studentMarks', {
                                        ...data.studentMarks,
                                        high_school_percentage: toEn(
                                            e.target.value,
                                        ),
                                    })
                                }
                                type="number"
                                min={0}
                                max={150}
                                maxLength={3}
                            />
                        </div>
                        <div>
                            <TextInput
                                className="w-3/4 text-center disabled:bg-gray-50 placeholder:text-xs"
                                placeholder={t('percentage')}
                                disabled={data.standardMarks.subject_1 == 0}
                                name="studentMarks[subject_1]"
                                id="studentMarks[subject_1]"
                                onChange={(e) =>
                                    setData('studentMarks', {
                                        ...data.studentMarks,
                                        subject_1: toEn(e.target.value),
                                    })
                                }
                                type="number"
                                min={0}
                                max={100}
                                maxLength={2}
                            />
                        </div>
                        <div>
                            <TextInput
                                className="w-3/4 text-center disabled:bg-gray-50 placeholder:text-xs"
                                placeholder={t('percentage')}
                                disabled={data.standardMarks.subject_2 == 0}
                                name="studentMarks[subject_2]"
                                id="studentMarks[subject_2]"
                                onChange={(e) =>
                                    setData('studentMarks', {
                                        ...data.studentMarks,
                                        subject_2: toEn(e.target.value),
                                    })
                                }
                                type="number"
                                min={0}
                                max={100}
                            />
                        </div>
                        <div>
                            <TextInput
                                className="w-3/4 text-center disabled:bg-gray-50 placeholder:text-xs"
                                placeholder={t('percentage')}
                                disabled={data.standardMarks.subject_3 == 0}
                                name="studentMarks[subject_3]"
                                id="studentMarks[subject_3]"
                                onChange={(e) =>
                                    setData('studentMarks', {
                                        ...data.studentMarks,
                                        subject_3: toEn(e.target.value),
                                    })
                                }
                                type="number"
                                min={0}
                                max={100}
                            />
                        </div>
                        <div>
                            <TextInput
                                className="w-3/4 text-center disabled:bg-gray-50 placeholder:text-xxs"
                                placeholder={t('percentage')}
                                disabled={data.standardMarks.subject_4 == 0}
                                name="studentMarks.subject_4"
                                type="number"
                                min={0}
                                max={100}
                                onChange={(e) =>
                                    setData('studentMarks', {
                                        ...data.studentMarks,
                                        subject_4: toEn(e.target.value),
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div
                        className="w-full grid grid-cols-1 lg:grid-cols-5 gap-4  justify-
                    nter items-center p-5  "
                    >
                        <div>
                            {floor(data.resultMarks.high_school_percentage, 2)}
                        </div>
                        <div>{`${floor(data.resultMarks.subject_1, 2)}`}</div>
                        <div>{`${floor(data.resultMarks.subject_2, 2)}`}</div>
                        <div>{`${floor(data.resultMarks.subject_3, 2)}`}</div>
                        <div>{`${floor(data.resultMarks.subject_4, 2)}`}</div>
                    </div>
                </form>
            </div>
            <div className="flex w-3/4 lg:w-1/3 flex-row justify-center items-center rounded-xl px-6 border border-gray-100  bg-theme-100 hover:bg-theme-700  hover:text-white capitalize">
                <div className="p-4">{t('equivalentـrate')}</div>
                {data.rate > 0 && (
                    <div className="p-4 mx-4 rtl:border-r ltr:border-l border-gray-200">{`${floor(data.rate, 2)}`}</div>
                )}
            </div>
        </div>
    );
}
